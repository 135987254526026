import {
  CHECKBOX_LEF,
  FLAT_PICKER, SELECT_INPUT, TEXT_INPUT,
} from '@/views/components/DynamicForm/constants'
import { ref } from '@vue/composition-api'

export default function config() {
  const MODULE_NAME = 'settings-operation-hours'

  // Week configs
  const weekDays = ref({
    Monday: {
      day: 'Monday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Tuesday: {
      day: 'Tuesday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Wednesday: {
      day: 'Wednesday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Thursday: {
      day: 'Thursday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Friday: {
      day: 'Friday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Saturday: {
      day: 'Saturday',
      is_closed: true,
      times: [{
        id: 1,
        opens_at: '',
        closes_at: '',
      }],
    },
    Sunday: {
      day: 'Sunday',
      is_closed: true,
      times: [
        {
          id: 1,
          opens_at: '',
          closes_at: '',
        },
      ],
    },
  })

  // levels
  const levels = [
    { value: 'organization_level', name: 'Organization level' },
    { value: 'warehouse_level', name: 'Warehouse level' },
  ]
  // Table Handlers
  const tableColumns = [
    { key: 'actions', label: '', thStyle: { width: '1%' } },
    { key: 'name', label: 'Name', thStyle: { width: '30%' } },
    {
      key: 'full_day', label: 'Full Day', thStyle: { width: '5%' }, tbStyle: { width: '20%' },
    },
    { key: 'dates', label: 'Dates', thStyle: { width: '30%' } },
  ]

  const fields = {
    hour: {
      type: TEXT_INPUT,
      label: 'Payment Term Name',
      rules: 'required',
    },
    name: {
      type: TEXT_INPUT,
      rules: 'simple-required',
    },
    start_date: {
      type: FLAT_PICKER,
      rules: 'required',
    },
    end_date: {
      type: FLAT_PICKER,
      rules: 'required',
    },
    full_day: {
      type: CHECKBOX_LEF,
    },
    start_time: {
      type: SELECT_INPUT,
      placeholder: '2pm',
      rules: 'required',
      store: 'operationHours',
      options: {
        label: 'text',
      },
    },
    end_time: {
      type: SELECT_INPUT,
      placeholder: '5pm',
      rules: 'required',
      store: 'operationHours',
      options: {
        label: 'text',
      },
    },
  }
  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    // hour: 'numeric',
    // minute: 'numeric',
  }

  return {
    MODULE_NAME,
    tableColumns,
    fields,
    weekDays,
    levels,
    dateOptions,
  }
}
