import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'system/operation-hours'
const Actions = crudActions(endpoint)
const getOperationHours = ({ commit }, params) => new Promise((resolve, reject) => {
  axios.get(endpoint, {
    params,
  })
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

const operationHoursLevel = (ctx, payload) => axios.post(`${endpoint}-level`, payload)

export default {
  ...Actions,
  getOperationHours,
  operationHoursLevel,
}
