<template>
  <div>
    <validation-observer
      ref="operationHoursVal"
    >

      <h1
        class="font-medium-5 font-weight-bolder"
      >
        {{ $t('Normal Weekly schedule') }}
      </h1>
      <div>
        <table class="operation-hours-table">
          <thead>
            <tr>
              <th style="width: 130px;">
                Day
              </th>
              <th style="width: 200px;">
                Opens at
              </th>
              <th style="width: 200px;">
                Closes at
              </th>
            </tr>
          </thead>
          <tbody>
            <tr style="height: 16px" />
            <tr
              v-for="(weekDay, index) in operationHourForm.operationHours"
              :key="index"
              class="pb-2"
            >
              <td class="d-flex">
                <div
                  class="mb-1"
                  style="position: relative; top: -4px"
                >
                  <span class="text-capitalize">{{ weekDay.day }}</span>
                  <div class="d-flex align-items-center">
                    <b-form-checkbox
                      v-model="weekDay.is_closed"
                      :disabled="isEdit"
                    />
                    <span style="position:relative; top: 3px">Closed</span>
                  </div>
                </div>
              </td>
              <td
                v-show="!weekDay.is_closed"
                style="vertical-align: top"
              >
                <div
                  v-for="(day, _index) in weekDay.times"
                  :key="_index"
                >
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Opens At')"
                    rules="required"
                    :vid="`hours_opens_at_${String(day.id)}`"
                  >
                    <div
                      class="d-inline-flex"
                      style="padding-right: 43px"
                    >
                      <LTimePickerInput
                        :id="`hours_opens_at_${String(day.id)}`"
                        v-model="day.opens_at"
                        :name="`hours_opens_at_${String(day.id)}`"
                        class="time-operation-hours-form"
                        :is-only-button="true"
                        :the-size="sizeOfIcon"
                        :field="{}"
                        :is-editable="isEdit"
                      />
                      <b-form-input
                        :id="`hours_text_${String(day.id)}`"
                        v-model="day.opens_at"
                        type="time"
                        style="height: 33px"
                        :disabled="isEdit"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="font-size: 14px"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </td>
              <td
                v-show="!weekDay.is_closed"
                style="vertical-align: top"
              >
                <div
                  v-for="(dayHours, _index) in weekDay.times"
                  :key="_index"
                  class="d-flex"
                >
                  <validation-provider
                    #default="{ errors }"
                    rules="required"
                    :name="$t('Closes At')"
                    :vid="`hours_${String(dayHours.id)}`"
                  >
                    <div class="d-inline-flex">
                      <LTimePickerInput
                        :id="`hours_${String(dayHours.id)}`"
                        v-model="dayHours.closes_at"
                        :name="`hours_${String(dayHours.id)}`"
                        class="time-operation-hours-form"
                        :is-only-button="true"
                        :the-size="sizeOfIcon"
                        :field="{}"
                        :is-editable="isEdit"
                      />
                      <b-form-input
                        :id="`hours_text_${String(dayHours.id)}`"
                        v-model="dayHours.closes_at"
                        type="time"
                        style="height: 33px"
                        :disabled="isEdit"
                      />
                    </div>
                    <small
                      class="text-danger"
                      style="font-size: 14px"
                    >{{ errors[0] }}</small>
                  </validation-provider>
                  <b-button
                    v-show="dayHours.id === 1"
                    variant="light"
                    class="operation-hours-table-add-hours cursor-pointer border-dotted"
                    :disabled="isEdit"
                    @click="addNewHoursOperation(weekDay.day)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      size="16"
                    />
                  </b-button>
                  <b-button
                    v-show="dayHours.id > 1"
                    variant="light"
                    class="operation-hours-table-add-hours cursor-pointer border-dotted"
                    @click="removeNewHoursOperation(weekDay.day, dayHours.id)"
                  >
                    <feather-icon
                      icon="MinusIcon"
                      size="16"
                    />
                  </b-button>
                </div>
              </td>
              <td />
            </tr>
          </tbody>
        </table>
      </div>
      <closures-form :is-edit="isEdit" />
    </validation-observer>
    <validation-observer>
      <portal to="body-footer">
        <div class="d-flex mt-2 pb-1 justify-content-between">
          <div>
            <b-button
              v-if="isFormChanged"
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="loader"
            >
              <feather-icon
                icon="LCancelIcon"
                size="16"
              />
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-else
              class="cancelBtn font-medium-1 font-weight-bolder"
              variant="outline-primary"
              @click="$router.push({ name: 'settings' })"
            >
              {{ $t('Back to Setting') }}
            </b-button>
          </div>
          <div>
            <b-overlay
              :show="isLoading"
              rounded
              opacity="0.6"
              spinner-small
              spinner-variant="success"
              class="d-inline-block ml-1"
            >
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="!isFormChanged || isLoading"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </b-overlay>
          </div>
        </div>
      </portal>
    </validation-observer>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>
import {
  BFormCheckbox, BButton, VBTooltip, BFormInput, BOverlay,
} from 'bootstrap-vue'
import config from '@/views/settings/fulfillment/operation-hours/OperationHoursConfig'
import { scrollToError } from '@core/utils/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, simpleRequired } from '@/libs/validations/validations'
import ClosuresForm from '@/views/settings/fulfillment/operation-hours/components/ClosuresForm.vue'
import LTimePickerInput from '@/views/components/DynamicForm/components/LTimePickerInput.vue'
import sortBy from 'lodash/sortBy'

export default {
  name: 'OperationHoursForm',
  components: {
    BOverlay,
    ClosuresForm,
    BFormCheckbox,
    BFormInput,
    BButton,
    ValidationObserver,
    ValidationProvider,
    LTimePickerInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      value: '',
      sortBy: 'id',
      sizeOfIcon: 'sm',
      isSortDirDesc: true,
      onSubmit: false,
      showPastDates: false,
      secondHoursList: false,
      config: {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        mode: 'single',
      },
      simpleRequired,
      weekDaysOnClosed: [],
      isLoading: false,
    }
  },
  computed: {
    operationHourForm() {
      return this.$store.state[this.MODULE_NAME].operationHourForm
    },
    operationHoursFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].operationHoursFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.operationHourForm) !== this.operationHoursFormClone
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.operationHoursVal.reset()
      const smth = JSON.parse(this.operationHoursFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    addNewHoursOperation(weekDay) {
      const dayTimes = this.operationHourForm.operationHours[weekDay]
      dayTimes.times.push({
        id: dayTimes.times.length + 1,
        opens_at: dayTimes.times[dayTimes.times.length - 1].closes_at,
        closes_at: '',
      })
    },
    removeNewHoursOperation(weekDay, dayHoursId) {
      const dayTimes = this.operationHourForm.operationHours[weekDay]
      dayTimes.times = dayTimes.times.filter(item => item.id !== dayHoursId)
    },
    submit() {
      this.error = {}
      this.onSubmit = true
      this.$refs.operationHoursVal.validate()
        .then(success => {
          if (success) {
            const { operationHours } = this.operationHourForm
            const data = {
              operation_hours: Object.fromEntries(Object.entries(operationHours).map(([key, item]) => [key, item.is_closed ? { ...item, times: [] } : item])),
              warehouse_id: this.warehouse.id,
            }

            this.isLoading = true
            this.sendNotification(this, data, `${this.MODULE_NAME}/create`)
              .then(() => {
                this.$emit('refetch')
              })
              .catch(err => {
                this.errorNotification(this, err)
                this.error = err.response?.data
              }).finally(() => {
                this.onSubmit = false
                this.isLoading = false
              })
          } else {
            scrollToError(this, this.$refs.operationHoursVal)
          }
        })
    },
    getProps(field, fullDay) {
      return {
        ...field,
        rules: !fullDay ? 'required' : '',
      }
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      levels, weekDays, fields, tableColumns, dateOptions,
    } = config()
    const MODULE_NAME = 'settings-operation-hours'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      levels,
      weekDays,
      tableColumns,
      fields,
      dateOptions,
    }
  },

}

</script>
<style lang="scss">
.time-operation-hours-form {
  display: flex;
  width: max-content;
  button{
    padding: 7px ;
  }
}
</style>
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.calendarInputs {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.delete-btn {
  padding: 10px;
}
.customShadow:not(.bootstrap-touchspin):focus-within{
  box-shadow: none;
}
.calendar-icon-custom{
  z-index: 10;
  background-color: transparent;
  height: 30px;
  width: 18px;
  margin-top: 2px;
  margin-right: -25px;
}

.operation-hours-table {
  &__select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 1px !important;
    text-overflow: '' !important;
    background: none !important;
    width: 100px;
    height: 35px;
    padding: 4px;
    text-align: center;
  }

  &-add-hours {
    min-width: 35px;
    height: 35px;
    margin-left: 8px;
  }
  grayIcon {
    padding-top: 10px;
  }
}
</style>
